// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'
import cn from 'classnames'

import { Store } from 'Store'

type ImagePath = string;

type Props = {
    theme: {
        background: string,
        color: string,
        logo: ImagePath,
    }
}

const Footer = (props: Props) => {
    const { state } = React.useContext(Store)
    const { t, theme, className, boxShadow } = props

    const clientAddress = React.useMemo(() => {
        return state.campaign.client.address ? [
            [state.campaign.client.address.line1, state.campaign.client.address.line2],
            state.campaign.client.address.city,
            [state.campaign.client.address.province, state.campaign.client.address.postalCode]
        ].filter(Boolean).map(i => Array.isArray(i) ? i.filter(Boolean).join(' ') : i).join(', ') : null
    }, [state.campaign.client.address])

    return (
        <div className={cn(styles.container, {
            [styles.boxShadow]: boxShadow
        }, className)} style={{
            background: theme.background,
            color: theme.color,
            '--border-color': theme.borderColor,
            '--shadow-color': theme.shadowColor
        }}>
            <div className={styles.innerContainer}>
                <p className={styles.dealershipInfo} style={{ '--dealership-color': theme.dealershipColor }}>
                    <span style={{ color: theme.dealershipClientColor }}>{state.campaign.client.name}</span>
                    {clientAddress && ` | ${clientAddress}`}
                </p>

                <p className={styles.disclaimer}>{state.campaign.termsAndConditions ? state.campaign.termsAndConditions : t('disclaimer')}</p>

                <img className={styles.logo} src={theme.logo} alt='' />
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Footer))
