
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns3ef7c4e5c320f7ca02395a0d86cea27fen_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '3ef7c4e5c320f7ca02395a0d86cea27f', flattenObject(ns3ef7c4e5c320f7ca02395a0d86cea27fen_US), true, true);
        
            import ns3ef7c4e5c320f7ca02395a0d86cea27fen from './en.yaml'
            i18n.addResourceBundle('en', '3ef7c4e5c320f7ca02395a0d86cea27f', flattenObject(ns3ef7c4e5c320f7ca02395a0d86cea27fen), true, true);
        
        export default '3ef7c4e5c320f7ca02395a0d86cea27f';
    