// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'
import { withTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'
import OrderItem from 'components/OrderItem'
import { Scrollbars } from 'react-custom-scrollbars'
import Icon from './Icon'
import CloseIcon from './CloseIcon'

import cn from 'classnames'

export const HeadingMenu = withTranslation(transDomain)(withTheme(supportedThemes)(({
    confirm,
    theme,
    items = 3,
    t,
    className,
    onClickClose,
    value
}) => {
    return (
        <div className={cn(styles.headingMenu, className)}>
            <CloseIcon onClick={onClickClose} />
            <h3 style={{ color: theme.color }}>Your Cart</h3>
            <div className={styles.textAreaMenu} style={{
                color: theme.color,
                '--title-color': theme.titleColor
            }}>
                <strong>{value}</strong>  <span>Remaining</span>
            </div>
        </div>
    )
}))

export const Heading = withTranslation(transDomain)(withTheme(supportedThemes)(({
    confirm,
    theme,
    items = 0,
    t,
    className,
    onClickIcon,
    handleConfirm,
    value
}) => {
    return (
        <div className={cn(styles.heading, className)}
            style={{
                '--background-color': theme.backgroundColor,
                '--background-mobile-color': theme.backgroundMobileColor
            }}>
            <div className={styles.textArea} style={{ '--title-color': theme.titleColor }}>
                <strong style={{
                    color: theme.valueColor
                }}>{value}</strong>  <span>Remaining</span>
            </div>
            <div className={styles.confirmBtn}>
                <Confirm text='Confirm Rewards' onClick={handleConfirm} isHeader />
            </div>
            <div className={styles.iconContainer} onClick={onClickIcon} style={{
                '--bubble-color': theme.bubbleColor,
                '--buble-text-color': theme.bubbleTextColor
            }}>
                <Icon backgroundColor={theme.bucketBg} />
                {items > 0 &&
                <span className={cn(styles.bubble, {
                    '--border-color': theme.borderColor,
                    [styles.outline]: theme.outlineBubble
                })}>
                    {items}
                </span>
                }
            </div>
        </div>
    )
}))

export const Confirm = withTranslation(transDomain)(withTheme(supportedThemes)(({ noOutline, text, theme, t, className, isHeader, onClick, disabled }) => {
    return (
        <div className={cn(styles.confirm, className, {
            [styles.outline]: theme.outlineBtn && !noOutline,
            [styles.isHeader]: isHeader,
            [styles.disabled]: disabled
        })} onClick={onClick} style={{ '--button-color': theme.buttonColor }}>
            {text}
        </div>
    )
}))

export const ConfirmContainer = withTranslation(transDomain)(withTheme(supportedThemes)(({ text, theme, t, className, children }) => {
    return (
        <div className={cn(styles.ConfirmContainer, className)} style={{ backgroundColor: theme.colors.primary }}>
            {children}
        </div>
    )
}))

export const OrderSummaryLayout = withTranslation(transDomain)(withTheme(supportedThemes)(({
    theme,
    t,
    heading,
    footer,
    children,
    className,
    bodyClassName,
    footerClassName,
    setRef
}) => {
    return (
        <div
            className={cn(styles.container, className)}
            style={{ backgroundColor: theme.sidebar.backgroundColor }}>
            {heading}
            <div className={cn(styles.orderSummaryLayout, bodyClassName)}>
                <Scrollbars
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    autoHeight
                    autoHeightMax={'100vh'}
                    ref={setRef ? s => { setRef(s) } : setRef}
                    thumbMinSize={30}>
                    <div className={styles.orderSummaryLayoutBody}>{children}</div>
                </Scrollbars>
            </div>
            <div className={cn(styles.footer, footerClassName)}>
                {footer}
            </div>
        </div>
    )
}))

function OrderSummary ({ theme, t, heading, footer, className }) {
    // const { state } = React.useContext(Store)
    return (
        <OrderSummaryLayout heading={heading} footer={footer} className={className}>
            <OrderItem />
            <OrderItem />
            <OrderItem />
        </OrderSummaryLayout>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(OrderSummary))
