// @flow
import * as React from 'react'

import { Currency } from 'i18n/NumberFormatter'
import _ from 'lodash'
import withTheme from 'hoc/withTheme'
import { Store } from 'Store.js'
import { withTranslation } from 'react-i18next'
import cn from 'classnames'
import PopupMessage from 'components/PopupMessage'
import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'
import * as Form from 'components/Form'
import transDomain from './translations/index.translations'

function ShoppingItem ({ theme, image, src, t, height, width, onClick, xl, options }) {
    const { state } = React.useContext(Store)
    const [selectedSku, setSku] = React.useState(null)
    const currencyFormatter = Currency(state.currency, state.locale)
    const [showError, setShowError] = React.useState(null)
    const [
        currentValues,
        valuesDictionary,
        formatValuesDictionary
    ] = React.useMemo(() => {
        const formatValuesDictionary = {}
        const valuesDictionary = {}
        const currentValues = options.map(v => {
            formatValuesDictionary[`${v.sku}-${v.value}`] = currencyFormatter.format(v.value)
            valuesDictionary[`${v.sku}-${v.value}`] = v
            return formatValuesDictionary[`${v.sku}-${v.value}`]
        })
        return [currentValues, valuesDictionary, formatValuesDictionary]
    }, [currencyFormatter, options])

    const handleOnClick = React.useCallback(() => {
        if (!selectedSku) return setShowError(true)
        onClick(valuesDictionary[selectedSku].sku, valuesDictionary[selectedSku].value, image, src)
        setSku(null)
    }, [image, onClick, selectedSku, src, valuesDictionary])

    const handleOnChange = React.useCallback(value => {
        const current = _.keys(formatValuesDictionary).find(key => formatValuesDictionary[key] === value)
        setSku(current)
    }, [formatValuesDictionary])

    React.useEffect(() => {
        if (showError) {
            setTimeout(() => setShowError(null), 2500)
        }
    }, [ showError ])

    const withLabel = React.useMemo(() => {
        let withLabel = false
        options.forEach(({ sku }) => {
            if (sku.match('SHIFT') && !withLabel) {
                withLabel = true
            }
        })
        return withLabel
    }, [options])

    return (
        <div
            className={cn(styles.container, {
                [styles.withLabel]: withLabel
            })}
            style={{
                borderColor: theme.borderColor,
                '--color-selected': theme.primaryColor,
                backgroundColor: theme.background
            }}
        >
            <div className={styles.description} style={{
                color: theme.descriptionColor
            }}>{src}</div>
            <div className={cn(styles.imageContainer, { [styles.xl]: xl })}>
                <img src={image} alt={src} width={width} height={height} />
            </div>
            <Form.Field label={''}>
                <Form.Dropdown
                    placeholder={t('placeholder')}
                    options={currentValues}
                    trans={t}
                    defaultValue={formatValuesDictionary[selectedSku]}
                    onChange={handleOnChange}
                    customStyle={theme.dropdown}
                    className={styles.Dropdown}
                />
            </Form.Field>
            <span className={cn(styles.button, {
                [styles.outline]: theme.outline
            })} style={{
                '--border-color': theme.button.background
            }} onClick={handleOnClick}>
                ADD TO CART
            </span>
            {showError && <PopupMessage containerStyle={{
                left: '0.5em',
                right: '0.5em',
                bottom: '2em'
            }} message={'Please select an amount'} />}
        </div>
    )
}

function areEqual (prevProps, nextProps) {
    return _.isEqual(prevProps, nextProps)
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(React.memo(ShoppingItem, areEqual)))
