// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import { Store } from 'Store'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

import transDomain from './translations/index.translations'

function MessageIncentive (props) {
    const { state } = React.useContext(Store)
    return (
        <div className={styles.message}>
            <p className={styles.messageParagraph}>{props.message}</p>
            <p className={styles.messageTitle} style={{
                color: props.theme.titleColor
            }}>{props.title}</p>
            {!props.hiddeMessageFooter &&
                <div className={styles.messageFooter}>
                    <strong>{state.campaign.contactInfo.name}</strong>&nbsp;
                    {state.campaign.contactInfo.position}, {state.campaign.client.name}
                </div>
            }
        </div>
    )
}

export default withTheme(supportedThemes)(withTranslation(transDomain)(MessageIncentive))
