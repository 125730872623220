// @flow
import React from 'react'
import { Store } from 'Store'
import { Redirect, withRouter } from 'react-router-dom'
import MainLogo from 'components/MainLogo'
import Footer from 'components/Footer'
import SummaryItem from 'components/SummaryItem'
import { withTranslation } from 'react-i18next'
import transDomain from './translations/index.translations'
import { sendAppointmentDate, sendClientMessage } from 'Actions'
import { AppointmentWidgetWrapper } from '@shift-marketing/event-websites-chatbot'
import '@shift-marketing/event-websites-chatbot/dist/main.css'

import styles from './styles.module.scss'
import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes'
import useTimeout from '../../hook/useTimeout'
import { Currency } from 'i18n/NumberFormatter'

function Checkout ({
    theme,
    t,
    location
}) {
    const { state } = React.useContext(Store)
    const [num, addOrRemove] = React.useState({})
    const [appointmentWidget, setAppointmentWidget] = React.useState(false)

    const name = state.campaign.contactInfo.name

    const rewardsDictionary = React.useMemo(() => {
        const dictionary = {}
        state.campaign.incentives.rewards.available.forEach(reward => {
            reward.options.forEach(({ sku, value }) => {
                const _id = `${sku}-${value}`
                dictionary[_id] = { ...reward, sku, value }
            })
        })
        return dictionary
    }, [ state.campaign.incentives.rewards.available ])

    React.useEffect(() => {
        // Update/sync current rewards state using API data when mounted/submited
        if (state.lead && state.lead.incentives && state.lead.incentives.rewards) {
            const nextstate = {}
            state.lead.incentives.rewards.forEach(({ sku, value }) => {
                const _id = `${sku}-${value}`
                if (rewardsDictionary[_id]) {
                    nextstate[_id] = {
                        ...rewardsDictionary[_id],
                        qty: nextstate[_id] ? ++nextstate[_id].qty : 1
                    }
                }
            })
            addOrRemove(nextstate)
        }
    }, [rewardsDictionary, state.lead])

    useTimeout(() => {
        if (state.campaign.hasOwnProperty('incentives') && state.campaign.incentives.hasOwnProperty('onlineBooking') && !state.campaign.expired) {
            let localTimezone
            try {
                localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            } catch {
                localTimezone = state.campaign.timezone
            }
            setAppointmentWidget(
                <AppointmentWidgetWrapper
                    onlineBooking={state.campaign.incentives.onlineBooking}
                    timezone={localTimezone}
                    lead={state.lead}
                    clientName={state.campaign.client.name}
                    expiryDate={state.campaign.expiryDate}
                    colors={theme.colors}
                    actions={{
                        sendAppointmentDate,
                        sendClientMessage
                    }}
                    currencyFormatter={Currency(state.currency, state.locale)}
                />
            )
        }
    }, 5000)

    if (!state.lead) {
        return <Redirect to={`${location.pathname.replace('/checkout', '')}${location.search}`} push />
    }

    if (!state.lead || !state.lead.incentives || !state.lead.incentives.rewards) {
        return <Redirect to={`${location.pathname.replace('checkout', 'shopping')}${location.search}`} push />
    }

    return (
        <>
            { appointmentWidget }
            <div className={styles.container} style={{
                '--background-color': theme.backgroundColor,
                '--title-color': theme.titleColor,
                '--body-background-color': theme.body.backgroundColor
            }}>
                <div className={styles.mainLogo}>
                    <MainLogo />
                </div>
                <div className={styles.info}>
                    <h1> {t('thanks_message', {
                        name
                    })}</h1>
                    <p>{t('paragraph')}</p>
                </div>
                <div className={styles.body}>
                    <h2>{t('subTitle')}</h2>
                    <div className={styles.itemsArea}>
                        {Object.keys(num).map(key => (
                            <SummaryItem
                                key={key}
                                qty={num[key].qty}
                                value={num[key].value}
                                name={num[key].name}
                                iconUrl={num[key].iconUrl}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <Footer boxShadow />
        </>
    )
}

export default withTranslation(transDomain)(withRouter(withTheme(supportedThemes)(Checkout)))
