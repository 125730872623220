
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns1f852d2c3bf6ea59b61ec074f4b1f077en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '1f852d2c3bf6ea59b61ec074f4b1f077', flattenObject(ns1f852d2c3bf6ea59b61ec074f4b1f077en_US), true, true);
        
            import ns1f852d2c3bf6ea59b61ec074f4b1f077en from './en.yaml'
            i18n.addResourceBundle('en', '1f852d2c3bf6ea59b61ec074f4b1f077', flattenObject(ns1f852d2c3bf6ea59b61ec074f4b1f077en), true, true);
        
        export default '1f852d2c3bf6ea59b61ec074f4b1f077';
    