/* eslint-disable no-sparse-arrays */
/* eslint-disable no-const-assign */
// @flow

import * as React from 'react'

import Header from 'components/Header'
import { Currency } from 'i18n/NumberFormatter'
import ShoppingItem from 'components/ShoppingItem'
import PopupMessage from 'components/PopupMessage'
import {
    Heading,
    Confirm as Continue,
    OrderSummaryLayout,
    HeadingMenu,
    ConfirmContainer
} from 'components/OrderSummary'
import TopBar from 'components/TopBar'
import ModalContainer from 'components/ModalContainer'
import MessageStep from 'components/MessageStep'
import MenuRight from 'components/MenuRight'
import Footer from 'components/Footer'
import { updateRewards } from 'Actions'
import OrderItem from 'components/OrderItem'
import { useMediaQuery } from 'beautiful-react-hooks'
import { Scrollbars } from 'react-custom-scrollbars'
import ShoppingLayout from 'components/ShoppingLayout'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import styles from './styles.module.scss'
import { Store } from 'Store'

export default (props: Props) => {
    const { state, dispatch } = React.useContext(Store)
    const { campaign, lead } = state
    const [errorCredits, setErrorCredits] = React.useState(false)
    const [noCreditsMessage, setNoCreditsMessage] = React.useState(false)
    const [submitted, setSubmitted] = React.useState(false)
    const name = state.campaign.contactInfo.name
    const currencyFormatter = Currency(state.currency, state.locale)
    const isTabletOrMobile = useMediaQuery('(max-width: 1024px)')
    const [show, toggleModal] = React.useState(true)
    const [showError, setShowError] = React.useState(false)
    const [step, setStep] = React.useState(1)
    const [showMenu, toggleMenu] = React.useState(false)
    const toggleRightMenu = () => toggleMenu(state => !state)
    const [num, addOrRemove] = React.useState({})
    const [ref, setRef] = React.useState(null)

    const rewardsValue = React.useMemo(() => {
        if (!lead) {
            return null
        }
        return campaign.incentives.rewards.type === 'fixed'
            ? campaign.incentives.rewards
            : campaign.models.find(m => m.make === lead.fields.newVehicleMake && m.name === lead.fields.newVehicleModel && m.year === lead.fields.newVehicleModelYear).voucherValue
    }, [lead, campaign.incentives.rewards, campaign.models])

    const [values, setValues] = React.useState({ itemsLength: 0, amount: rewardsValue })

    const rewardsDictionary = React.useMemo(() => {
        const dictionary = {}

        state.campaign.incentives.rewards.available.forEach(reward => {
            reward.options.forEach(({ sku, value }) => {
                const _id = `${sku}-${value}`
                dictionary[_id] = { ...reward, sku, value }
            })
        })
        return dictionary
    }, [state.campaign.incentives.rewards.available])

    React.useEffect(() => {
        // Update/sync current rewards state using API data when mounted/submited

        if (state.lead && state.lead.incentives && state.lead.incentives.rewards) {
            const nextstate = {}
            state.lead.incentives.rewards.forEach(({ sku, value }) => {
                const _id = `${sku}-${value}`
                if (rewardsDictionary[_id]) {
                    nextstate[_id] = {
                        ...rewardsDictionary[_id],
                        qty: nextstate[_id] ? ++nextstate[_id].qty : 1
                    }
                }
            })
            addOrRemove(nextstate)
        }
    }, [rewardsDictionary, state.lead])

    React.useEffect(() => {
        if (ref) {
            setTimeout(() => {
                ref.scrollToBottom()
            }, 0)
        }
    }, [num, ref])

    const addMore = React.useCallback(key => {
        return (sku, value, iconUrl, name) => addOrRemove(state => {
            if (values.amount - value < 0) {
                setErrorCredits(true)
                return state
            } else if (values.amount === 0) {
                setNoCreditsMessage(true)
                return state
            }

            const _id = `${sku}-${value}`
            const qty = state[_id] ? ++state[_id].qty : 1

            return {
                ...state,
                [_id]: {
                    qty,
                    sku,
                    key,
                    value,
                    name,
                    iconUrl
                }
            }
        })
    }, [values])

    const setQtyOfItem = React.useCallback(key => {
        return (sku, value, iconUrl, name, length) => addOrRemove(state => {
            const _id = `${sku}-${value}`
            const qty = length

            const currentValue = values.amount + parseInt(state[_id].qty * state[_id].value)

            if ((currentValue - (value * parseInt(length))) === 0) {
                setNoCreditsMessage(true)
            } else if ((currentValue - (value * parseInt(length))) < 0) {
                setErrorCredits(true)
                return state
            }
            return {
                ...state,
                [_id]: {
                    qty,
                    sku,
                    key,
                    value,
                    name,
                    iconUrl
                }
            }
        })
    }, [values])

    const handleRemove = React.useCallback((key) => {
        return () => {
            addOrRemove(state => {
                const nextstate = { ...state }
                if (nextstate[key].qty - 1 === 0) {
                    return _.omit(nextstate, key)
                }
                nextstate[key].qty = nextstate[key].qty - 1
                return nextstate
            })
        }
    }, [])

    const handleSubmit = React.useCallback(async () => {
        if (values.itemsLength === 0) {
            return alert('You must select at least one item')
        }

        if (values.amount > 0) {
            return setShowError(true)
        }

        try {
            const rewards = []
            Object.keys(num).forEach(key => {
                for (let i = 0; i < num[key].qty; i++) {
                    rewards.push({
                        sku: num[key].sku,
                        value: num[key].value
                    })
                }
            })
            await updateRewards(dispatch, rewards)
            setSubmitted(true)
        } catch (err) {
            console.error(err)
        }
    }, [dispatch, num, values])

    React.useEffect(() => {
        let amount = 0
        let itemsLength = 0

        Object.keys(num).forEach(key => {
            itemsLength += parseInt(num[key].qty)
            amount += parseInt(num[key].qty) * parseInt(num[key].value)
        })

        setValues({
            amount: rewardsValue - amount,
            itemsLength
        })
    }, [num, rewardsValue])

    React.useEffect(() => {
        if (showError) {
            setTimeout(() => setShowError(null), 2500)
        }
    }, [ showError ])

    React.useEffect(() => {
        if (errorCredits) {
            setTimeout(() => setErrorCredits(null), 4000)
        }
    }, [ errorCredits ])

    React.useEffect(() => {
        if (noCreditsMessage) {
            setTimeout(() => setNoCreditsMessage(null), 4000)
        }
    }, [ noCreditsMessage ])

    if (submitted) {
        return <Redirect to={`${props.location.pathname.replace('shopping', 'checkout')}${props.location.search}`} push />
    }

    if (!state.lead) {
        return <Redirect to={`${props.location.pathname.replace('/shopping', '')}${props.location.search}`} push />
    }

    return (
        <ShoppingLayout>
            <TopBar locale={state.locale} date={state.campaign.expiryDate}/>
            <ModalContainer
                show={show}
                onClickOverlay={() => toggleModal(false)}
            >
                <Scrollbars
                    autoHide={false}
                    autoHeight
                    autoHeightMin={'100%'}
                    universal={true}
                    autoHeightMax={'calc(100vh - 200px)'}
                    thumbMinSize={30}>
                    <div className={styles.messageSlider}>
                        {step === 1 &&
                        <MessageStep
                            title={<span>{`Congratulations, ${name}!`}<br />{`You have received ${currencyFormatter.format(rewardsValue)} to spend on a personal shopping spree.`}</span>}
                        >
                            <p className={styles.messageP}>Your vehicle choice has been sent to our team and a representative will be contacting you shortly.</p>
                            <div className={styles.continue} onClick={() => {
                                setStep(2)
                                toggleModal(false)
                            }}>
                                <Continue text='START SHOPPING' />
                            </div>
                        </MessageStep>
                    }
                    </div>
                </Scrollbars>
            </ModalContainer>
            {errorCredits &&
                <PopupMessage
                    message={'You do not have enough credit'}
                    noBubble
                    shake
                    containerStyle={{
                        position: 'fixed',
                        left: 'calc(50% - 138px)',
                        bottom: 'calc(50% - 32.5px)',
                        right: 'unset',
                        zIndex: '9999',
                        minHeight: '65px',
                        borderRadius: '0px',
                        boxShadow: '3px 4px 8px rgba(125, 125, 125, 0.25)'
                    }}
                />
            }
            {isTabletOrMobile &&
                <div className={styles.fixedHeading}>
                    <Heading
                        items={values.itemsLength}
                        onClickIcon={toggleRightMenu}
                        handleConfirm={handleSubmit}
                        value={currencyFormatter.format(values.amount)}
                    />
                </div>
            }

            <div className={styles.container}>
                <div className={styles.grid}>
                    <div className={styles.leftArea}>
                        <Header />
                        <Scrollbars
                            autoHide
                            autoHideTimeout={1000}
                            autoHideDuration={200}
                            autoHeight
                            autoHeightMax={'100vh'}
                            thumbMinSize={30}>
                            <div className={styles.shoppingItemList}>
                                {state.campaign.incentives.rewards.available.map(({
                                    key,
                                    iconUrl,
                                    name,
                                    options
                                }) => (
                                    <ShoppingItem
                                        key={key}
                                        onClick={addMore(key)}
                                        image={iconUrl}
                                        src={name}
                                        options={options}
                                    />
                                ))}
                            </div>
                        </Scrollbars>
                    </div>
                    <MenuRight
                        show={showMenu}
                        onClickOverlay={toggleRightMenu}
                        disabled={!isTabletOrMobile} className={styles.MenuRight}>
                        <OrderSummaryLayout
                            className={styles.orderSummary}
                            footerClassName={styles.orderFooter}
                            bodyClassName={styles.orderBody}
                            setRef={setRef}
                            heading={isTabletOrMobile ? <HeadingMenu
                                value={currencyFormatter.format(values.amount)}
                                onClickClose={toggleRightMenu} />
                                : <Heading
                                    items={values.itemsLength}
                                    value={currencyFormatter.format(values.amount)}
                                    handleConfirm={handleSubmit}
                            />}
                            footer={isTabletOrMobile ? null
                                : <>
                                    {noCreditsMessage &&
                                        <PopupMessage
                                            message={'You have $0 remaining, edit your cart or confirm rewards to continue'}
                                            bottom
                                            containerStyle={{
                                                bottom: '9em',
                                                left: '0em',
                                                right: '0em',
                                                borderRadius: '4px',
                                                width: '100%',
                                                margin: '0px auto'
                                            }}
                                        />
                                    }
                                    {showError && <PopupMessage
                                        containerStyle={{
                                            bottom: '9em',
                                            left: '0em',
                                            right: '0em',
                                            borderRadius: '4px',
                                            width: '100%',
                                            margin: '0px auto'
                                        }}
                                        bottom
                                        message={'You must have $0 remaining to confirm gifts'}
                                    />}
                                    <Continue text='CONFIRM REWARDS' onClick={handleSubmit} disabled={values.amount > 0} />
                                </>}
                        >
                            {Object.keys(num).map(key => (
                                <span key={key}>
                                    <OrderItem
                                        setQtyOfItem={setQtyOfItem(key)}
                                        qty={num[key].qty}
                                        sku={num[key].sku}
                                        key={key}
                                        value={num[key].value}
                                        name={num[key].name}
                                        iconUrl={num[key].iconUrl}
                                        onClick={handleRemove(key)}
                                    />
                                </span>
                            ))}
                        </OrderSummaryLayout>
                    </MenuRight>
                </div>
            </div>

            <div className={styles.continueTablet}>
                <ConfirmContainer>
                    <Continue
                        noOutline
                        text='CONFIRM REWARDS'
                        onClick={handleSubmit}
                        disabled={values.amount > 0}
                    />
                    {showError && <PopupMessage
                        containerStyle={{
                            top: '-2em',
                            left: '0',
                            right: '0',
                            width: '100%',
                            margin: '0 auto',
                            borderRadius: '4px'
                        }}
                        bottom
                        message={'You must have $0 remaining to confirm gifts'}
                />}
                    {noCreditsMessage &&
                    <PopupMessage
                        message={'You have $0 remaining, edit your cart or confirm rewards to continue'}
                        bottom
                        containerStyle={{
                            top: '-2em',
                            left: '1em',
                            right: '1em',
                            width: '100%',
                            margin: '0 auto',
                            borderRadius: '4px'
                        }}
                    />
                }
                </ConfirmContainer>
            </div>
            <Footer boxShadow className={styles.footer} />
        </ShoppingLayout>
    )
}
